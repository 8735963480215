















































































































































































































































































import AutoPostBoostingErrors from '@/components/AutoPostBoosting/AutoPostBoostingErrors.vue'
import AutoPostBoostingPlacements from '@/components/AutoPostBoosting/AutoPostBoostingPlacements.vue'
import BaseInputNumberWithButtons
  from '@/components/BaseComponents/BaseInputNumberWithButtons/BaseInputNumberWithButtons.vue'
import BaseSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import BaseInputCurrency from '@/components/BaseInputCurrency.vue'
import NewUiAccordion from '@/components/NewUIComponents/NewUiAccordion.vue'
import PostBoostingService from '@/services/api/PostBoostingService'
import props from '@/views/AutoPostBoosting/steps/props'
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'AutoPostBoostingAdSet',
  components: { BaseInputCurrency, NewUiAccordion, AutoPostBoostingErrors, BaseSelect, BaseInputNumberWithButtons, AutoPostBoostingPlacements },
  mixins: [props],
  data () {
    return {
      adSetNameTemplates: null,
      customAudiences: null,
      optimizationAvailable: null,
      BUDGET_OPTIMIZATION_DATES: [
        {
          label: 'Total',
          value: 1
        },
        {
          label: 'Daily',
          value: 2
        }
      ],
      CAMPAIGN_BID_STRATEGY: [
        {
          label: 'Lowest cost without cap',
          value: 'LOWEST_COST_WITHOUT_CAP'
        },
        {
          label: 'Lowest cost with bid cap',
          value: 'LOWEST_COST_WITH_BID_CAP'
        }
      ]
    }
  },
  computed: {
    name: {
      get (): string {
        return this.data.name
      },
      set (value: string): void {
        this.$emit('remove-error', 'name')
        this.data.name = value
      }
    },
    days: {
      get (): string {
        return this.data.days_for_promote_ads
      },
      set (value: string): void {
        this.$emit('remove-error', 'days_for_promote_ads')
        this.data.days_for_promote_ads = value
      }
    },
    bid: {
      get (): number | null {
        return this.data.bid
      },
      set (value: number | null): void {
        this.data.bid = value
      }
    },
    currency (): string {
      return this.meta?.currency?.symbol || '?'
    },
    meta_optimize_for (): Array<unknown> {
      return this.meta?.optimize_for || []
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    async getAdSetNameTemplates (): Promise<void> {
      this.adSetNameTemplates = (await PostBoostingService.getAdSetNameTemplates(this.service)).data.data
    },
    async getAdSetsCustomAudiences (): Promise<void> {
      try {
        const result = await PostBoostingService.getAdSetsCustomAudiences(this.service, this.ruleId)
        if (result?.data?.data instanceof Array) {
          this.customAudiences = result.data.data.map(_ => ({ label: _.name, value: _.fb_id }))
        }
      } catch (error) {
        console.error(error)
      }
    },
    templateNameNestedDropdownClickHandler (event: PointerEvent): void {
      let possiblyParent = event.target as HTMLElement
      while (possiblyParent) {
        if (possiblyParent.classList.contains('template-name-nested-dropdown')) {
          possiblyParent.dataset.open = possiblyParent.dataset.open === 'open' ? '' : 'open'
        }
        possiblyParent = possiblyParent.parentElement
      }
    },
    frontEndErrorsHandler () {
      let result = false
      if (!this.data.name) {
        result = true
        this.$emit('set-error', 'name', 'Name field is required!')
      }
      if (!this.data.audience_fb_id) {
        result = true
        this.$emit('set-error', 'audiences_data', 'Audience is required!')
      }
      if (this.data.placements) {
        if (!this.data.placements.devices?.length) {
          result = true
          this.$emit('set-error', 'ad_placements.devices', 'At least one device is required!')
        }
        if (!this.data.placements.platforms?.length) {
          result = true
          this.$emit('set-error', 'ad_placements.platforms', 'At least one placement is required!')
        }
      }
      return result
    },
    postData (): unknown {
      const result: Record<string, unknown> = {
        name: this.data.name,
        audiences_data: this.data.audience_fb_id ? [
          {
            audiences: [
              {
                fb_id: +this.data.audience_fb_id,
                is_included: 1
              }
            ]
          }
        ] : null,
        ad_placements: this.data.placements || undefined,
        days_for_promote_ads: this.data.days_for_promote_ads
      }

      if (this.optimizationAvailable) {
        result.budget = this.optimizationAvailable ? this.data.budget : null
        result.budget_type = this.optimizationAvailable ? this.data.budget_type : null
        result.bidding = this.optimizationAvailable ? this.data.bidding : null
        result.optimize_for = this.optimizationAvailable ? this.data.optimize_for : null
        result.pay_for = this.optimizationAvailable ? this.data.pay_for : null
        result.bid = this.optimizationAvailable ? this.data.bid : null
      }
      return result
    },
    async post () {
      return PostBoostingService.postAdSetsSetting(this.service, this.ruleId, this.postData())
    }
  },
  created () {
    if (this.ruleId) {
      this.$emit('reload-ad-set-rule', () => {
        this.getAdSetNameTemplates()
        this.getAdSetsCustomAudiences()
      })
      this.$emit('reload-campaign-rule', (axiosResponse) => {
        this.optimizationAvailable = !axiosResponse.data.data?.attributes.budget_amount
      })
    } else {
      this.addNotification({ body: 'The rule has not been created yet!', type: 'danger' })
      this.$router.replace({
        name: 'PostBoostingNewDetails',
        params: { ...this.$route.params }
      })
    }
  }
})

