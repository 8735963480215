
































import { PropType } from 'vue'

const NUMBERS = '0 1 2 3 4 5 6 7 8 9'.split(' ')
const FUNCTIONAL = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']
const ALLOWED_CODES = [...NUMBERS.map(n => `Digit${n}`), ...NUMBERS.map(n => `Numpad${n}`), ...FUNCTIONAL]

export default {
  name: 'BaseInputNumberWithButtons',
  props: {
    value: {
      type: Number,
      required: true
    },
    step: {
      type: [String, Number] as PropType<number | `${number}`>
    },
    max: {
      type: [String, Number] as PropType<number | `${number}`>
    },
    min: {
      type: [String, Number] as PropType<number | `${number}`>
    },
    unsigned: {
      type: Boolean
    },
    integer: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    parse (): typeof parseInt | typeof parseFloat {
      return this.integer ? parseInt : parseFloat
    },
    $_step (): number {
      return this.parse(this.step)
    },
    $_max (): number | undefined {
      const result = this.max !== undefined && this.parse(this.max)

      if (typeof result === 'number' && isFinite(result)) {
        return result
      }

      return undefined
    },
    $_min (): number | undefined {
      const result = this.min !== undefined && this.parse(this.min)

      if (typeof result === 'number' && isFinite(result)) {
        return result
      }

      return undefined
    },
    allowedKeys (): Array<string> {
      return [...ALLOWED_CODES, ...(this.integer ? [] : ['Period']), ...(this.unsigned ? [] : ['Minus'])]
    }
  },
  methods: {
    keydownHandler (event: KeyboardEvent): void {
      if (!this.allowedKeys.includes(event.code)) {
        event.preventDefault()
      }
    },
    onBlur (event: InputEvent): void {
      const target = event.target as HTMLInputElement

      if (target) {
        target.value = this.value
      }
    },
    inputHandler (event: InputEvent): void {
      const target = event.target as HTMLInputElement

      if (target?.value) {
        this.input(this.parse(target.value))
      }
    },
    input (value: number): void {
      if (
        typeof value !== 'number' ||
        !isFinite(value) ||
        (this.$_min !== undefined && this.$_min > value) ||
        (this.$_max !== undefined && this.$_max < value)
      ) {
        return
      }
      this.$emit('input', value)
    }
  }
}
