var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("header"),_vm._t("action",null,{"saveChanges":_vm.post,"frontEndErrorsHandler":_vm.frontEndErrorsHandler}),_c('div',{staticClass:"block block-type-2"},[_c('h2',{staticClass:"h2-type-2"},[_vm._v(" Ad Set ")]),_c('p',{staticClass:"p-bold p-small margin-top-6 margin-bottom-2"},[_vm._v(" Ad set name ")]),_c('div',{staticClass:"auto-post-boosting__inputs-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control input-type-1",attrs:{"placeholder":"Set a name template for your campaign name","disabled":!_vm.serverData},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('base-select',{staticClass:"template-name-dropdown",attrs:{"max-width":170,"loading":!_vm.adSetNameTemplates,"disabled":!_vm.adSetNameTemplates || !_vm.serverData,"options":_vm.adSetNameTemplates || [],"placeholder":"Dynamic Parameter","close-on-select":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"template-name-nested-dropdown"},[_c('div',{staticClass:"template-name-nested-dropdown__group",on:{"click":_vm.templateNameNestedDropdownClickHandler}},[_c('img',{attrs:{"width":"10","src":require("@/assets/chevrone-icon.svg"),"alt":""}}),_c('b',[_vm._v(" "+_vm._s(option.group)+" ")])]),_c('div',{staticClass:"template-name-nested-dropdown__body"},_vm._l((option.fields),function(field){return _c('span',{key:field.field,on:{"click":function($event){_vm.name += field.field}}},[_vm._v(" "+_vm._s(field.name)+" ")])}),0)])]}}])})],1),_c('auto-post-boosting-errors',{attrs:{"errors":_vm.errors.name}}),_c('p',{staticClass:"p-bold p-small margin-top-6 margin-bottom-2"},[_vm._v(" Promote ads for ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('base-input-number-with-buttons',{attrs:{"disabled":!_vm.serverData,"max":"100","min":"1","integer":"","unsigned":""},model:{value:(_vm.days),callback:function ($$v) {_vm.days=$$v},expression:"days"}}),_c('p',{staticClass:"p-bold p-small margin-left-2"},[_vm._v(" Days ")])],1)],1),_c('div',{staticClass:"block block-type-2 margin-top-5"},[_c('h2',{staticClass:"h2-type-2"},[_vm._v(" Target audience ")]),_c('p',{staticClass:"text-gray p-small margin-top-3"},[_vm._v(" Choose a target audience for your Ad Set. ")]),_c('div',{staticClass:"target-audience"},[_c('base-select',{staticClass:"text-start-capital",attrs:{"disabled":!_vm.meta,"max-width":300,"options":_vm.customAudiences || [],"value":_vm.customAudiences ? _vm.customAudiences.find(function (_) { return _.value === _vm.data.audience_fb_id; }) : undefined,"placeholder":"Choose audience"},on:{"input":function($event){_vm.data.audience_fb_id = $event.value; _vm.$emit('remove-error', 'audiences_data')}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_vm._v(" "+_vm._s(selected.label)+" ")]}},{key:"option",fn:function(option){return _vm._l((option),function(opt,key){return _c('div',{key:key},[_vm._v(_vm._s(opt.label))])})}}])}),_c('a',{attrs:{"href":_vm.meta ? ("https://business.facebook.com/adsmanager/audiences?act=" + (_vm.meta.ad_account)) : undefined}},[_c('button',{staticClass:"btn btn-custom",attrs:{"disabled":!_vm.meta}},[_c('i',{staticClass:"fak fa-plus-regular margin-right-2"}),_vm._v(" New audience ")])]),_vm._m(0)],1),_c('auto-post-boosting-errors',{attrs:{"errors":_vm.errors.audiences_data}})],1),_c('new-ui-accordion',{attrs:{"collapsed":!_vm.optimizationAvailable}},[_c('div',{staticClass:"block block-type-2 margin-top-5"},[_c('h2',{staticClass:"h2-type-2"},[_vm._v(" Optimization & delivery ")]),_c('p',{staticClass:"text-gray p-small margin-top-3"},[_vm._v(" Choose the event you want to optimise for in your Ad Set. ")]),_c('p',{staticClass:"p-bold p-small margin-top-4 margin-bottom-2"},[_vm._v(" Budget for each post ")]),_c('div',{staticClass:"auto-post-boosting__inputs-inline auto-post-boosting__inputs-inline_small"},[_c('base-input-currency',{attrs:{"disabled":!_vm.serverData,"currency":_vm.currency},model:{value:(_vm.data.budget),callback:function ($$v) {_vm.$set(_vm.data, "budget", $$v)},expression:"data.budget"}}),_c('base-select',{attrs:{"max-width":200,"options":_vm.BUDGET_OPTIMIZATION_DATES,"disabled":!_vm.serverData,"value":_vm.data.budget_type && _vm.BUDGET_OPTIMIZATION_DATES[_vm.data.budget_type - 1],"append-to-body":""},on:{"input":function($event){_vm.data.budget_type = $event.value}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_vm._v(" "+_vm._s(selected.label)+" ")]}},{key:"option",fn:function(option){return _vm._l((option),function(opt,key){return _c('div',{key:key},[_vm._v(_vm._s(opt.label))])})}}])})],1),(_vm.meta && _vm.meta.min_budgets.min_daily_budget_imp > _vm.data.budget && !!_vm.serverData)?_c('p',{staticClass:"p-small gray-text margin-top-1"},[_vm._v(" Minimum budget: "),_c('span',{staticClass:"p-small danger-text margin-top-1"},[_vm._v(" "+_vm._s(_vm.meta.min_budgets.min_daily_budget_imp)+_vm._s(_vm.currency)+" ")])]):_vm._e(),_c('p',{staticClass:"p-bold p-small margin-top-4 margin-bottom-2"},[_vm._v(" Optimize for ")]),_c('base-select',{staticClass:"text-start-capital",attrs:{"max-width":300,"options":_vm.meta_optimize_for,"disabled":!_vm.meta,"value":_vm.data.optimize_for},on:{"input":function($event){_vm.data.optimize_for = $event; _vm.data.pay_for = (_vm.meta && _vm.meta.pay_for[_vm.data.optimize_for] && _vm.meta.pay_for[_vm.data.optimize_for][0]) || ''}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_vm._v(" "+_vm._s(selected && selected.label && selected.label.toLowerCase().replaceAll('_', ' ') || '')+" ")]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(" "+_vm._s(option && option.label && option.label.toLowerCase().replaceAll('_', ' ') || '')+" ")]}}])}),_c('p',{staticClass:"p-bold p-small margin-top-4 margin-bottom-2"},[_vm._v(" Pay for ")]),_c('base-select',{staticClass:"text-start-capital",attrs:{"max-width":300,"options":(_vm.meta && _vm.meta.pay_for[_vm.data.optimize_for]) || [],"disabled":!_vm.meta,"value":_vm.data.pay_for},on:{"input":function($event){_vm.data.pay_for = $event}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_vm._v(" "+_vm._s(selected && selected.label && selected.label.toLowerCase().replaceAll('_', ' ') || '')+" ")]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(" "+_vm._s(option && option.label && option.label.toLowerCase().replaceAll('_', ' ') || '')+" ")]}}])}),_c('p',{staticClass:"p-bold p-small margin-top-4 margin-bottom-2"},[_vm._v(" Bidding strategy ")]),_c('base-select',{staticClass:"text-start-capital",attrs:{"max-width":300,"options":_vm.CAMPAIGN_BID_STRATEGY,"disabled":!_vm.meta,"value":_vm.CAMPAIGN_BID_STRATEGY.find(function (_) { return _.value === _vm.data.bidding; })},on:{"input":function($event){_vm.bid = (_vm.data.bidding = $event.value) === _vm.CAMPAIGN_BID_STRATEGY[1].value ? 10 : null}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_vm._v(" "+_vm._s(selected.label)+" ")]}},{key:"option",fn:function(option){return _vm._l((option),function(opt,key){return _c('div',{key:key},[_vm._v(_vm._s(opt.label))])})}}])}),_c('new-ui-accordion',{attrs:{"collapsed":_vm.bid === null}},[_c('p',{staticClass:"p-bold p-small margin-top-4 margin-bottom-2"},[_vm._v(" Bid ")]),_c('base-input-currency',{attrs:{"disabled":!_vm.serverData,"currency":_vm.currency},model:{value:(_vm.bid),callback:function ($$v) {_vm.bid=$$v},expression:"bid"}})],1)],1)]),_c('div',{staticClass:"block block-type-2 margin-top-5"},[_c('h2',{staticClass:"h2-type-2"},[_vm._v(" Placements ")]),_c('p',{staticClass:"text-gray p-small margin-top-3"},[_vm._v(" Choose devices and platforms where you want to show your Ads. ")]),_c('auto-post-boosting-placements',{staticClass:"margin-top-6",on:{"remove-error":function($event){return _vm.$emit('remove-error', $event)}},model:{value:(_vm.data.placements),callback:function ($$v) {_vm.$set(_vm.data, "placements", $$v)},expression:"data.placements"}}),_c('auto-post-boosting-errors',{attrs:{"errors":_vm.errors['ad_placements.devices']
          ? { text: 'One or more devices required!' }
          : undefined}}),_c('auto-post-boosting-errors',{attrs:{"errors":(_vm.errors['ad_placements.platforms'] || _vm.errors['ad_placements.positions'])
        ? { text: 'One or more platforms required!' }
        : undefined}})],1),_vm._t("footer",null,{"nextStep":_vm.post,"frontEndErrorsHandler":_vm.frontEndErrorsHandler,"disabled":_vm.optimizationAvailable === null})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"p-small"},[_c('i',{staticClass:"fak fa-circle-exclamation-solid gray-text margin-right-2"}),_vm._v(" Opens Facebook Audience Manager ")])}]

export { render, staticRenderFns }