















































































import NewUiAccordion from '@/components/NewUIComponents/NewUiAccordion.vue'
import NewUiCheckbox from '@/components/NewUIComponents/NewUiCheckbox.vue'
import TabSwitcher from '@/components/TabSwitcher.vue'
import Vue, { PropType } from 'vue'

type AdPlacements = Record<string, Array<string>> | null

export default Vue.extend({
  name: 'AutoPostBoostingPlacements',
  components: { NewUiCheckbox, NewUiAccordion, TabSwitcher },
  data () {
    return {
      PLACEMENT_TABS: [
        {
          id: 0,
          placementType: '1',
          name: 'Custom',
          title: 'Custom'
        },
        {
          id: 1,
          placementType: '2',
          name: 'Automatic',
          title: 'Automatic'
        }
      ] as const
    }
  },
  props: {
    value: {
      type: Object as PropType<AdPlacements>,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    desktop: {
      get (): boolean {
        return this.value?.devices.includes('desktop') || false
      },
      set (value: boolean): void {
        this.$emit('remove-error', 'ad_placements.devices')
        if (this.value) {
          if (value && !this.value.devices.includes('desktop')) {
            this.value.devices.push('desktop')
          }
          if (!value && this.value.devices.includes('desktop')) {
            this.value.devices = this.value.devices.filter(_ => _ !== 'desktop')
          }
        }
      }
    },
    mobile: {
      get (): boolean {
        return this.value?.devices.includes('mobile') || false
      },
      set (value: boolean): void {
        this.$emit('remove-error', 'ad_placements.devices')
        if (this.value) {
          if (value && !this.value.devices.includes('mobile')) {
            this.value.devices.push('mobile')
          }
          if (!value && this.value.devices.includes('mobile')) {
            this.value.devices = this.value.devices.filter(_ => _ !== 'mobile')
          }
        }
      }
    },
    feed: {
      get (): boolean {
        return this.value?.positions.includes('feed') || false
      },
      set (value: boolean): void {
        this.$emit('remove-error', 'ad_placements.platforms')
        this.$emit('remove-error', 'ad_placements.positions')
        if (this.value) {
          if (value && !this.value.positions.includes('feed')) {
            this.value.positions.push('feed')
            this.patchFacebook()
          }
          if (!value && this.value.positions.includes('feed')) {
            this.value.positions = this.value.positions.filter(_ => _ !== 'feed')
            this.patchFacebook()
          }
        }
      }
    },
    marketplace: {
      get (): boolean {
        return this.value?.positions.includes('marketplace') || false
      },
      set (value: boolean): void {
        this.$emit('remove-error', 'ad_placements.platforms')
        this.$emit('remove-error', 'ad_placements.positions')
        if (this.value) {
          if (value && !this.value.positions.includes('marketplace')) {
            this.value.positions.push('marketplace')
            this.patchFacebook()
          }
          if (!value && this.value.positions.includes('marketplace')) {
            this.value.positions = this.value.positions.filter(_ => _ !== 'marketplace')
            this.patchFacebook()
          }
        }
      }
    },
    stream: {
      get (): boolean {
        return this.value?.positions.includes('stream') || false
      },
      set (value: boolean): void {
        this.$emit('remove-error', 'ad_placements.platforms')
        this.$emit('remove-error', 'ad_placements.positions')
        if (this.value) {
          if (value && !this.value.positions.includes('stream')) {
            this.value.positions.push('stream')
            this.patchInstagram()
          }
          if (!value && this.value.positions.includes('stream')) {
            this.value.positions = this.value.positions.filter(_ => _ !== 'stream')
            this.patchInstagram()
          }
        }
      }
    },
    story: {
      get (): boolean {
        return this.value?.positions.includes('story') || false
      },
      set (value: boolean): void {
        this.$emit('remove-error', 'ad_placements.platforms')
        this.$emit('remove-error', 'ad_placements.positions')
        if (this.value) {
          if (value && !this.value.positions.includes('story')) {
            this.value.positions.push('story')
            this.patchInstagram()
          }
          if (!value && this.value.positions.includes('story')) {
            this.value.positions = this.value.positions.filter(_ => _ !== 'story')
            this.patchInstagram()
          }
        }
      }
    }
  },
  methods: {
    patchFacebook (): void {
      if (this.value) {
        if (this.value.platforms.includes('facebook')) {
          if (!this.feed && !this.marketplace) {
            this.value.platforms = this.value.platforms.filter(_ => _ !== 'facebook')
          }
        } else {
          if (this.feed || this.marketplace) {
            this.value.platforms.push('facebook')
          }
        }
      }
    },
    patchInstagram (): void {
      if (this.value) {
        if (this.value.platforms.includes('instagram')) {
          if (!this.stream && !this.story) {
            this.value.platforms = this.value.platforms.filter(_ => _ !== 'instagram')
          }
        } else {
          if (this.stream || this.story) {
            this.value.platforms.push('instagram')
          }
        }
      }
    }
  }
})
